import * as React from 'react'
import { Link } from 'gatsby'

import Meta from '../components/Meta'
import Layout from '../components/Layout'

function NotFoundPage() {
  return (
    <>
      <Meta
        title="Page Not Found"
        description="Sorry, we couldn’t find what you were looking for. Try heading back to our home page."
        location={{ pathname: '/404/' }}
      />
      <Layout accent>
        <section className="m-16 lg:mb-96">
          <h1 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray text-center">
            Page not found
          </h1>
          <p className="mt-5 max-w-prose mx-auto text-center font-semibold text-gray-light tracking-wider">
            Sorry, we couldn’t find what you were looking for.
          </p>
          <p className="mt-5 max-w-prose mx-auto text-center font-semibold text-gray-light tracking-wider">
            Try heading back to our{' '}
            <Link to="/" className="text-green hover:underline">
              home page
            </Link>
            .
          </p>
        </section>
      </Layout>
    </>
  )
}

export default NotFoundPage
